@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url(https://my.calenso.com/webroot/fonts/opensans/Open-Sans-normal-300.woff) format('woff'), url(https://my.calenso.com/webroot/fonts/opensans/Open-Sans-normal-300.woff2) format('woff2'), url(https://my.calenso.com/webroot/fonts/opensans/Open-Sans-normal-300.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://my.calenso.com/webroot/fonts/opensans/Open-Sans-normal-400.eot);
  src: local('Open Sans'), url(https://my.calenso.com/webroot/fonts/opensans/Open-Sans-normal-400.woff) format('woff'), url(https://my.calenso.com/webroot/fonts/opensans/Open-Sans-normal-400.woff2) format('woff2'), url(https://my.calenso.com/webroot/fonts/opensans/Open-Sans-normal-400.eot?#iefix) format('embedded-opentype'), url(https://my.calenso.com/webroot/fonts/opensans/Open-Sans-normal-400.svg#OpenSans) format('svg'), url(https://my.calenso.com/webroot/fonts/opensans/Open-Sans-normal-400.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url(https://my.calenso.com/webroot/fonts/opensans/Open-Sans-normal-600.woff) format('woff'), url(https://my.calenso.com/webroot/fonts/opensans/Open-Sans-normal-600.woff2) format('woff2'), url(https://my.calenso.com/webroot/fonts/opensans/Open-Sans-normal-600.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url(https://my.calenso.com/webroot/fonts/opensans/Open-Sans-normal-700.woff) format('woff'), url(https://my.calenso.com/webroot/fonts/opensans/Open-Sans-normal-700.woff2) format('woff2'), url(https://my.calenso.com/webroot/fonts/opensans/Open-Sans-normal-700.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url(https://my.calenso.com/webroot/fonts/opensans/Open-Sans-normal-800.woff) format('woff'), url(https://my.calenso.com/webroot/fonts/opensans/Open-Sans-normal-800.woff2) format('woff2'), url(https://my.calenso.com/webroot/fonts/opensans/Open-Sans-normal-800.ttf) format('truetype');
  font-display: swap;
}

.cw-overflow-hidden {
  overflow: hidden;
}

:root {
  --cbcw-border-color: rgba(26, 26, 26, 0.1);
  --cbcw-box-shadow-color: rgb(0 0 0 / 8%);
  --cbcw-gray-color: rgba(26, 26, 26, 0.61);
  --cbcw-font-color: #000000;
  --cbcw-primary-color: #5555FF;
}

@import "./assets/scss/_variable.scss";

.calenso-booking {
  @import "bootstrap/scss/bootstrap";
  @import "angular-calendar/scss/angular-calendar";
  @import "assets/css/wizard";
  @import "assets/css/calendar";
  @import "assets/css/ng2-select";
  @import "./app/base";
  @import "./assets/scss/material-overwrite/tabs.scss";
  @import "./assets/scss/material-overwrite/custom-field.scss";

  position: relative;
  line-height: 16px !important;
  color: #3a4a66;
  font-family: "Open Sans", sans-serif !important;
  text-align: left !important;

  &.compact-layout {
    color: var(--cbcw-font-color);
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .form-control {
    font-size: 13px;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px !important;
    color: #495057;
    display: block;
    font-weight: 400;
    height: calc(1.5em + 12px + 2px);
    line-height: 1.5;
    padding: 6px 12px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
    min-height: auto;
  }

  .modal-body {
    font-size: 14px;
  }

  .modal-title {
    margin: 0px !important;
  }

  .btn-primary {
    color: #fff;
    background-color: #3a4a66;
    border-color: #3a4a66;

    &:disabled {
      color: #fff;
      background-color: #3a4a66;
      border-color: #3a4a66;
    }

    &:hover {
      color: #fff;
      background-color: #3a4a66;
      border-color: #3a4a66;
    }

    &:focus {
      color: #fff;
      background-color: #3a4a66;
      border-color: #3a4a66;
    }

    &:active {
      color: #fff;
      background-color: #3a4a66;
      border-color: #3a4a66;
    }
  }

  /* ========== General styles for the widget =============== */

  .no-wrap {
    white-space: nowrap;
  }

  .words-break {
    word-wrap: break-word;
  }

  .loading {
    margin: 50px auto;
  }

  .row.widget {
    margin: 0;
  }

  .form-group {
    margin-bottom: 0px;
  }

  .intl-tel-input.separate-dial-code .selected-dial-code {
    font-size: 14px;
  }

  .intl-tel-input .country-list .country-name {
    font-size: 14px;
  }

  .intl-tel-input .country-list .dial-code {
    font-size: 14px;
  }

  .header {
    color: #777;
    border-top: medium none;
    cursor: default;
    /* font-weight: 700; */
    font-size: 14px;
    /* margin-top: 0px !important; */
    margin-top: 14px;
  }

  .input-label {
    font-family: "Open Sans";
    font-size: 13px;
    //color: rgb(51, 51, 51);
    color: #3a4a66;
    // margin-top: 10px;
  }

  .disabled-input {
    cursor: default;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    color: #999;
  }

  .form-input label:hover {
    color: #000;
  }

  .custom-field.form-group.summary-padding {
    // min-height: 70px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 16px;
  }

  .custom-field.form-group.meeting-providers-padding {
    margin-top: 12px;
  }

  .form-field-margin {
    margin-top: 16px;
  }

  .custom-textarea.form-group.summary-padding {
    // min-height: 160px;
    padding-left: 15px;
    padding-right: 15px;
  }

  label.col-xs-12.no-padding {
    padding-left: 0;
    margin-top: 7px;
  }

  .info-msg {
    font-size: 12px;
    padding: 5px 0 15px;
    border-bottom: 2px solid #e7e7e7;
  }

  // Solution for Safari bug with bootstrap row/col classes
  .container:before,
  .container:after,
  .row:before,
  .row:after {
    content: normal;
  }

  textarea:hover,
  input:hover,
  textarea:active,
  input:active,
  textarea:focus,
  input:focus,
  button:focus,
  button:active,
  button:hover,
  label:focus,
  .btn:active,
  .btn.active {
    outline: 0px !important;
    -webkit-appearance: none;
    appearance: none;
  }

  .form-control:focus,
  .form-control:active {
    box-shadow: none;
    border-color: rgb(206, 212, 218);
  }

  .additional-message {
    width: 80%;
    //margin-top: 30px;
    margin-left: 10%;
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
  }

  .btn-final {
    border-radius: 0;
    border-width: 0px;
    color: #dedede;
  }

  .glyphicon-spin {
    -webkit-animation: spin 1000ms infinite linear;
    animation: spin 1000ms infinite linear;
  }

  .reservation-error-message {
    --bs-alert-color: #842029;
    --bs-alert-bg: #f8d7da;
    --bs-alert-border-color: #f5c2c7;

    position: relative;
    padding: 16px;
    margin-bottom: 16px;
    color: var(--bs-alert-color);
    background-color: var(--bs-alert-bg);
    border: 1px solid var(--bs-alert-border-color);
    border-radius: 4px;
    font-size: 14px;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  /* cart styles */

  #cart-accordion .panel-body {
    padding: 20px 15px;
  }

  .cart-item-services .cart-item-service-item {
    border-bottom: 1px solid #e7e7e7;
    font-size: 13px;
    color: #5f5f5f;
  }

  #cart-accordion .cart-body {
    padding-left: 0px;
    padding-right: 0px;
  }

  .cart-item-services .cart-item-service-item:hover {
    color: #444;
  }

  .cart-item-services .cart-item-service-item {
    padding: 10px 0px;
  }

  .cart-item-totals {
    background-color: #e7e7e7;
    padding: 4px 0;
    color: #777;
    font-size: 14px;
  }

  .cart-item-controls {
    padding: 10px 0;
  }

  /* Messages */

  .alert-danger.summary-warning {
    background-color: transparent;
    padding: 3px 0px 0px 0;
    border: none;
    margin: 0;
    color: #ef6565;
    font-size: 12px;
    // margin-bottom: 7px;
  }

  .final-msg {
    border-radius: 20px;
    color: #292929;
    width: 86%;
    margin-top: 50px;
    margin-left: 7%;
    padding: 15px 0px;
  }

  .additional-message {
    width: 80%;
    //margin-top: 30px;
    margin-left: 10%;
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
  }

  .caption {
    font-size: 14px;
  }

  .icon-lock span {
    font-size: 22px;
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 20px;
  }

  .btn-final {
    border-radius: 0;
    border-width: 0px;
    color: #dedede;
  }

  .conf-bcg {
    background-color: #97cc2e;
  }

  .conf-bcg_wrong {
    background-color: #cc2e2e;
  }

  .conf-bcg_warning {
    background-color: #eea236;
  }

  /* ==== event widget ====*/

  .description-msg,
  .pointer {
    cursor: pointer;
  }

  .tooltip.fade {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }

  .intl-tel-input {
    display: block;
  }

  @media screen and (max-width: 767px) {
    .custom-field.cf-checkbox {
      padding-top: 0px;
    }
  }

  @media screen and (max-width: 480px) {
    .col-xxs-12 {
      width: 100%;
    }

    .col-xxs-6 {
      width: 50%;
    }

    .col-xxs-10 {
      width: 83.3333%;
    }

    .col-xxs-2 {
      width: 16.6666%;
    }
  }

  .appointment-checkbox {
    padding-left: 0px !important;
  }

  .appointment-services {
    padding-left: 0px;
  }

  .reset-form {
    text-align: right;
    margin-top: 5px;
    /* margin-right: 12px; */
    cursor: pointer;

    &-link {
      text-decoration: none;
      color: gray;
      font-size: 12px;
      text-align: right;
    }

    &-link:hover {
      color: gray;
      text-decoration: none;
    }

    &-icon {
      color: #f36a6a;
      margin-right: 5px;
      cursor: pointer;
      font-size: 15px;
    }
  }

  /* spinner: http://tobiasahlin.com/spinkit/ */
  .spinner {
    width: 40px;
    height: 40px;

    position: relative;
    margin: 100px auto;
  }

  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #333;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }

  .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  @-webkit-keyframes sk-bounce {

    0%,
    100% {
      -webkit-transform: scale(0);
    }

    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bounce {

    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }

    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }

  .no-worker-alert {
    font-size: 13px;
    margin-top: 15px;
  }

  // }

  /** Custom bootstrap column css */
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .row {
    margin-right: -15px !important;
    margin-left: -15px !important;
    margin-bottom: 0px !important;
  }

  &.compact-layout {
    .row {
      margin-top: calc(-1 * var(--bs-gutter-y)) !important;
      margin-right: calc(-0.5 * var(--bs-gutter-x)) !important;
      margin-left: calc(-0.5 * var(--bs-gutter-x)) !important;
    }

    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12 {
      padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
      padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
    }
  }

  .vulnerable-error-msg {
    color: #ef6565;
  }

  .intl-tel-input {
    display: block !important;
    width: 100%;
  }

  .intl-tel-input .country-list .flag-box,
  .intl-tel-input .country-list .country-name {
    font-size: 13px !important;
  }

  .intl-tel-input .country-list .country .dial-code {
    font-size: 13px !important;
  }

  .intl-tel-input.separate-dial-code .selected-dial-code {
    font-size: 13px !important;
  }

  .intl-tel-input:focus {
    outline: 0px;
  }

  .intl-tel-input.allow-dropdown .flag-container,
  .intl-tel-input.separate-dial-code .flag-container:focus {
    outline: 0px;
  }

  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 .selected-flag:focus {
    outline: 0px;
  }

  .iti__search-input {
    padding: 9px 12px;
  }

  .iti__country-list {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .iti__country-list .iti__flag,
  .iti__country-name {
    font-size: 13px;
  }

  .iti__dial-code {
    font-size: 13px;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    font-size: 13px !important;
  }

  .ng-select.ng-select-single .ng-select-container .ng-value-container,
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    font-size: 13px !important;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    font-size: 13px;
    top: auto;
  }

  .ng-select:focus {
    outline: none !important;
  }

  .ng-value-container:focus {
    outline: none !important;
  }

  .ng-input:focus {
    outline: none !important;
  }

  .ng-select-single:focus {
    outline: none !important;
  }

  .ng-select-searchable:focus {
    outline: none !important;
  }

  .ng-select-bottom:focus {
    outline: none !important;
  }

  .ng-select-focused {
    outline: none !important;
  }

  .ng-select-focused:focus {
    outline: none !important;
  }

  .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border-color: #cccccc !important;
    box-shadow: none !important;
  }

  .form-control:focus {
    border-color: #cccccc !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .ng-select-container {
    height: calc(0px + 0px + 0px) !important;
  }

  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: auto !important;
  }

  .ng-select {
    &:not(.cbw-meeting-type, .wroker-select-on-date-step) {
      .ng-select-container {
        min-height: 34px;
      }
    }
  }

  input:hover,
  input:active,
  input:focus,
  button:focus,
  button:active,
  button:hover,
  label:focus,
  .btn:active,
  .btn.active {
    outline: 0px !important;
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none !important;
  }

  .custom-control-input:focus~.custom-control-label::before {
    box-shadow: none !important;
    border-color: #3a4a66 !important;
  }

  .custom-control-input:active~.custom-control-label::before {
    box-shadow: none !important;
    background-color: #3a4a66 !important;
    border-color: #3a4a66 !important;
  }

  .cw-custom-title {
    font-weight: 500 !important;
  }

  .cw-custom-mobile-title {
    font-weight: 600 !important;
    font-size: 13px !important;
  }

  // Ng-Select CSS
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    font-size: 13px !important;
    background-color: #f5faff !important;
    border-radius: 2px !important;
    /* border: 1px solid #c2e0ff !important; */
    /* padding: 0px 0px 0px 6px !important; */
    /* white-space: nowrap !important; */
    border: 0.5px solid #c2e0ff !important;
  }

  .ng-has-value {
    .ng-value {
      font-size: 13px !important;
      // background-color: #f5faff !important;
      border-radius: 2px !important;
      /* border: 1px solid #c2e0ff !important; */
      /* padding: 0px 0px 0px 6px !important; */
      /* white-space: nowrap !important; */
      // border: 0.5px solid #c2e0ff !important;
    }
  }

  .ng-select-container {
    height: calc(0px + 0px + 0px) !important;
  }

  .custom-ng-value-label {
    display: inline-block !important;
    padding: 1px 5px !important;
  }

  a {
    color: #7676ff !important;

    :hover {
      text-decoration: underline !important;
    }
  }

  .skeleton-animation {
    position: relative;
    overflow: hidden;

    .bar {
      background-color: #e7e7e7;
      height: 14px;
      border-radius: 7px;
      width: 100%;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(100deg,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.5) 60%,
          rgba(255, 255, 255, 0) 80%);
      background-size: 200px 100%;
      background-position: -100px 0;
      background-repeat: no-repeat;
      animation-name: loading;
      animation-duration: 5s;
      animation-delay: 0s;
      animation-iteration-count: infinite;

    }
  }

  @keyframes loading {
    40% {
      background-position: 100% 0;
    }

    100% {
      background-position: 120% 0;
    }
  }
}

.compact-layout {
  .back-button-wrapper {
    padding-bottom: 50px;
  }

  .back-button {
    position: absolute;
    padding: 0;
    border: none;
    background-color: transparent;
    top: 27px;
    left: 0px;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;

    &.back-button-slots {
      top: 25px;
    }

    .icon-text {
      margin-left: 6px;
      font-weight: 600;
    }

    @media screen and (max-width: 991px) {
      top: 17px;
    }
  }
}
