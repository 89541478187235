:root {
  // Colors
  --calenso-primary-color: #3a4a66;
  --calenso-black: #000000;
  --calenso-focus-color: var(--calenso-black);
  --calenso-date-highlight-color: #EBF1FB;

  // Borders
  --calenso-date-highlight-border-radius: 25px;
  --calenso-focus-border: 2px;
  --calenso-primary-color: #3a4a66;
}
